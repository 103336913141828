.mainWrapper {
    margin: 0 auto;
    max-width: 1110px;
}

.wrapper{
    display: grid;
    max-width: 1110px;
    grid-template-columns: repeat(auto-fill, 285px);
    grid-gap: 117px 93px;
    justify-content: space-between;
    margin: 0 auto;
}

.title{
    margin: 55px 0 50px;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 45px;
}

img {
    width: 100%;
}

@media (max-width: 770px) {
    .wrapper{
        grid-gap: 50px 40px;
        justify-content: center;
    }

    .mainWrapper {
        grid-gap: 50px 40px;
        justify-content: center;
    }
}
@media (max-width: 280px) {
    .wrapper{
        grid-template-columns: repeat(auto-fill, 265px);
    }

    .mainWrapper {
        display: grid;
        grid-template-columns: repeat(auto-fill, 265px);
    }
}