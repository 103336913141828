.services {
    padding: 165px 0;
}

@media (max-width: 600px) {
.services{
    padding: 100px 0;
    }
}

@media only screen
and (min-device-width:380px)
and (max-device-width: 992px)
and (orientation : landscape) {
    .services {
        padding: 50px 0;
    }
}
