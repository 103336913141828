.mainText{
    padding: 210px 0 97px;
}

.title{
    font-size: 38px;
    line-height: 45px;
    margin: 0;
    max-width: 600px;
}

.subtitle{
    font-weight: normal;
    font-size: 23px;
    line-height: 25px;
    margin: 48px 0 0 0;
    max-width: 450px;
}

@media (max-width: 600px) {
    .title{
        margin: 0 auto;
        max-width: 250px;
    }
    .subtitle{
        margin: 48px auto 0;
        max-width: 250px;
    }
}

@media (max-width: 415px) {
    .mainText{
        padding: 150px 0 20px;
    }
    .title{
        font-size: 28px;
    }
    
    .subtitle{
        font-weight: normal;
        font-size: 18px;
    }

}
/* 
@media (max-width: 350px) {
    .title{
        font-size: 32px;
    }
} */

@media (max-width: 320px) {
    .mainText{
        padding: 100px 0 20px;
    }
}

@media only screen
and (min-device-width:380px)
and (max-device-width: 992px)
and (orientation : landscape) {
    .mainText{
        padding: 110px 0 30px;
    }
    .title{
        font-size: 28px;
    }
    .subtitle{
        font-size: 20px;
    }
}