.wrapper section:not(:nth-child(2)){
    max-width: 1110px;
    margin: 0 auto;
}

.tasksWrapper{
    padding: 120px 0;
}

.title{
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 100%;

    color: #000000;

    margin: 0 0 50px 0;
}

.titleWhite {
    color: #fff; 
}

.container{
    display: flex;
    flex-wrap: wrap;
}

.solutionsContainer{
    justify-content: space-between;
}

.count{
    font-style: normal;
    font-weight: 500;
    font-size: 96px;
    line-height: 127px;

    color: #000000;

    margin: 0 30px 0 0;
}

.tasksDescription{
    max-width: 883px;
    color: #000000;
}

.tasksDescription p:nth-child(1){
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
}

.tasksDescription p:nth-child(2){
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    opacity: 0.8;
    white-space: break-spaces;
}

.line{
    height: 1px;
    background: #C4C4C4;
    margin-top: 25px;
}

.tasksWrapper div:last-child .line{
    display: none;
}

.solutions{
    background: #000000;
}

.ourSolutionsWrapper{
    max-width: 1110px;
    margin: 0 auto;
    padding: 80px 0;
}

.solutionsDescription{
    max-width: 590px;
}

.solutionsDescription p:nth-child(1){
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;

    color: #fff;

    margin-top: 0;
}

.solutionsDescription p:nth-child(2){
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;

    color: #fff;

    opacity: 0.8;
    white-space: break-spaces;
}

.solutionImage{
    border-radius: 12px;
    width: 450px;
    height: 100%;
}

.ourSolutionsWrapper .container{
    margin-bottom: 50px;
}

.results{
    padding: 100px 0;
}

.resultsContainer{
    justify-content: space-between;
}

.longText{
    width: 60%;
    white-space: break-spaces;
}

.shortText{
    width: 30%;
    text-decoration: underline;
    font-weight: 600;
    font-size: 18px;
}

@media (max-width: 1200px) {
    .tasksWrapper{
        padding: 60px 0;
    }
    .wrapper section:not(:nth-child(2)){
        max-width: 90vw;
    }
    .tasksDescription{
        max-width: 600px;
    }
    .ourSolutionsWrapper{
        max-width: 90vw;
        padding: 60px 0;
    }
    .solutionsDescription{
        max-width: 450px;
    }
    .solutionImage{
        width: 350px;
    }
    .results{
        padding: 60px 0;
    }

}

@media (max-width: 992px) {
    .solutionsDescription{
        max-width: 350px;
    }
    .solutionImage{
        width: 250px;
    }
}

@media (max-width: 768px) {
    .title{
        font-size: 32px;
    }
    .count{
        font-size: 56px;
        line-height: 90px;
    }
}

@media (max-width: 576px) {
    .count{
        line-height: 56px;
    }
    .shortText{
        width: 40%;
    }
}
