.title{
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
}

.item{
    margin: 40px 0 8px;

    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;


    letter-spacing: 0.03em;
}

.itemDescription{
    margin: 0;

    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;

    letter-spacing: 0.03em;

    color: #C7C7C7;
}