.logoWrapper {
    width: 237px;
    height: 68px;

}
.logoWrapper img {
    height: 400%;
    width: 400%;
    vertical-align: middle;
    transform: scale3d(0.25, 0.25, 0.25);
    transform-origin: 0 0;
    filter: contrast(2);
}
.logoWrapper img:last-child {
    display: none;
}

@media (max-width: 600px) {
    .logoWrapper {
        width: 55px;
        height: 56px;
    }
    .logoWrapper img:last-child {
        display: block;
    }
    .logoWrapper img:first-child {
        display: none;
    }
}

@media only screen
and (min-device-width:380px)
and (max-device-width: 992px)
and (orientation : landscape) {
    .logoWrapper {
        width: 160px;
        height: 60px;
    }
}