.description{
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    max-width: 930px;
    margin: 120px auto 100px;
}

@media(max-width:415px){
    .description{
        max-width: 350px;
    }
    
}

@media only screen
and (min-device-width:380px)
and (max-device-width: 992px)
and (orientation : landscape) {
    .description{
        max-width: 580px;
    }
}