.wrapper{
    text-align: center;
    padding: 150px 0 250px;
}

.title{

    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 45px;
    max-width: 825px;
    margin: 0 auto;
}

.aboutInfo{

    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 130%;
    max-width: 680px;
    margin: 55px auto 0;
}

.moreAboutInfo{

    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 130%;
    max-width: 800px;
    margin: 25px auto -20px;
}

/* @media (max-width: 415px) {
    .wrapper{
        padding: 150px 0 180px;
    }
    .title{
        font-size: 28px;
    }
    
    .aboutInfo{
        max-width: 350px;
    }
    
    .moreAboutInfo{
        max-width: 350px;
    }
} */

@media (max-width: 415px) {
    .wrapper{
        padding: 150px 0 180px;
    }
    .title{
        font-size: 28px;
    }
    
    .aboutInfo{
        max-width: 350px;
    }
    
    .moreAboutInfo{
        max-width: 350px;
    }
}

@media only screen
and (min-device-width:380px)
and (max-device-width: 992px)
and (orientation : landscape) {
    .wrapper{
        padding: 100px 0 150px;
    }
    .aboutInfo{
        max-width: 580px;
    }
    .moreAboutInfo{
        max-width: 580px;
    }
}