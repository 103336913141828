.wrapper {
    display: flex;
    flex-direction: column;
}

.title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
}

.optionLabel {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    margin-left: 30px;
    color: #000;
}

.optionInput {
    width: 20px;
    height: 20px;
    vertical-align: middle;
}

@media (max-width: 550px){
    .optionsGroup{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .title {
        text-align: center;
        margin-left: 15px;
        margin-right: 15px;
    }
}