.title{
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
}

.subtitle{
    margin: 25px 0 0;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;

    letter-spacing: 0.03em;

    color: #686868;
}

.description{
    margin: 8px 0 0;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;

    letter-spacing: 0.03em;
}

.indent{
    margin: 8px 0 0;
}