.container{
    width: 256px;
    margin: 72px auto 0;
}

.getPresentation{
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 18px;
    height: 54px;
    background-color: transparent;
    transition: transform .4s ease-in;

    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width: 600px){
    .getPresentation:hover {
        transform: scale(1.1);
    }
}
