.logosContainer{
    margin: 160px auto 0;
    display: flex;
    max-width: 1000px;
    flex-wrap: wrap;
    justify-content: center;
}

.img{
    padding-left: 30px;
    padding-bottom: 60px;
    max-width: 185px;
} 

.img:nth-child(1) {
    padding-left: 0;
}

.img:nth-child(6) {
    padding-left: 90px;
    padding-right: 90px;
}

.img:nth-child(7) {
    padding-left: 0;
}

@media (max-width: 600px) {

    .img{
        width: 130px;
    }
    .img:nth-child(3) {
        padding-left: 0;
        padding-right: 0;
    }

    .img:nth-child(6) {
        padding-left: 30px;
        padding-right: 30px;
    }
}