.contactUs{
    background-color: #F9F9F9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 110px 0 206px;
}

.wrapper{
    margin: 80px auto 0;
    max-width: 960px;
}

.title{
    margin: 0;
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 25px;
    max-width: 900px;
}

.subtitle{
    margin: 0;
    padding-top: 30px;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 31px;


    color: #373737;
}


@media (max-width: 970px) {

    .title{
        margin: 0 auto;
        max-width: 90vw;
    }

    .subtitle{
        margin: 0 auto;
        max-width: 90vw;
    }
    
}

@media (max-width: 600px) {

    .title{
    text-align: center;
    font-size: 19px;
    }

    .subtitle{
        text-align: center;
    }
    .contactUs{
        padding: 110px 0 120px;
    }
}
@media only screen
and (min-device-width:380px)
and (max-device-width: 992px)
and (orientation : landscape) {
    .contactUs{
        padding: 80px 0 50px;
    }
}