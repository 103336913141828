.bioInfoContainer{
    display: flex;
    flex-direction: column;
}

.bioInfoContainer:nth-child(4){
    align-items: end;
}

.bioInfoContainer:nth-child(5){
    align-items: flex-end;
}

.bioInfoContainer img {
    height: 307px;
    filter: grayscale(1);
}
.fullName{
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;

    padding: 40px 0 20px;
}

.workPosition{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;

    color: #686868;

}

.about{
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 130%;

    margin: 40px 0 0;
}

@media (max-width: 600px) {
    .bioInfoContainer{
        align-items: center !important;
    }
}

@media (max-width: 280px) {
    .bioInfoContainer img {
        height: 290px;
        width: 265px;
    }
}
