.wrapper{
    max-width: 1110px;
    margin: 0 auto;
    color: #ffffff;
    height: 100vh;
}

@media (max-width: 1400px) {
    .wrapper{
        width: 80vw;
    }
}

@media (max-width: 1024px) {
    .wrapper{
        width: 90vw;
    }
}

@media (max-width: 770px) {
    .wrapper{
        max-width: 600px;
    }
}
