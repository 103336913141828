.item {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;

    letter-spacing: 0.03em;

    margin: 0 0 20px !important;

}

.item:last-child{
    margin-bottom: 0;
    display: flex;
    align-items: center;
}

.img {
    height: 40px;
    width: 50px;
    margin-left: -5px;
}

