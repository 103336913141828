.projects{
    background-color: #292A31;
    padding: 140px 0 160px;
}

@media (max-width: 600px) {
    .projects{
        padding: 100px 0;
    }
}

@media only screen
and (min-device-width:380px)
and (max-device-width: 992px)
and (orientation : landscape) {
    .projects{
        padding: 50px 0 90px;
    }
}
