.btn{
    width: 200px;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 4px;
    color: #000000;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    height: 45px;
    background-color: transparent;
    transition: transform .4s ease-in;
}
.red{
    background-color: #E13837;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
    color: #FFFFFF;
    width:200px;
    border: 1px solid #E13837;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    height: 45px;
    transition: transform .4s ease-in;
    margin-right: 15px;
}

@media (max-width: 600px) {
    .btn{
        margin: 10px auto;
    }
    .red{
        margin: 10px auto;
    }
}

@media (min-width: 600px){
    .btn:hover {
        transform: scale(1.1);
    }
    .red:hover {
        transform: scale(1.1);
    }
}
