.button{
    width: 124px;
    height: 40px;

    background: #E13837;
    border-radius: 4px;
    border: none;

    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: center;

    color: #F9F9F9;

    display: flex;
    justify-content: center;
    align-items: center;
}

@media(min-width:600px){
    .button{
        display: none;
    }
}