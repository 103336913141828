.active {
    width: 51px;
    height: 51px;
    border-radius: 30px;
    background: #6494BC;
    box-shadow: 0px 0px 4px #6494BC;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
}

.count {
    width: 51px;
    height: 51px;
    border-radius: 30px;
    background: #F2F2F2;
    box-shadow: 0px 0px 4px #D3D3D3;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
}

.stepsRound {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
}

.containerWrapper {
    max-width: 550px;
    margin: 120px auto 0;
}

.line {
    width: 128px;
    height: 3px;
    background: #6494BC;
    box-shadow: 0px 0px 4px #6494BC;
    margin: 0 10px;
}

.lineGrey {
    width: 128px;
    height: 3px;
    background: #F2F2F2;
    box-shadow: 0px 0px 4px #D3D3D3;
    margin: 0 10px;
}

@media (max-width: 500px) {
    .line {
        width: 60px;
    }
    .lineGrey {
        width: 60px;
    }
}