footer{
    background:  #292A31;
    color: #ffffff;
}

a{
    text-decoration: none;
    color: #ffffff;
}
.wrapper{
    max-width: 1110px;
    display: grid;
    grid-template-columns: .6fr .5fr 1fr;
    grid-template-areas: "a b c";
    grid-gap: 0 80px;
    margin: 0 auto;
    padding: 40px 0;
}

.wrapper div:nth-child(1){
    grid-area: a;
} 

.wrapper div:nth-child(2){
    grid-area: b;
} 

.wrapper div:nth-child(3){
    grid-area: c;
} 

@media (max-width:1250px){
    .wrapper{
        max-width: 80vw;
    }
}

@media (max-width:768px){
    .wrapper{
        grid-gap: 0 40px;
    }
}

@media (max-width:600px){
    footer{
        display: none;
    }
}

@media only screen
and (min-device-width:380px)
and (max-device-width: 992px)
and (orientation : landscape) {
    footer{
        display: none;
    }
}