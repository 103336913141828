.cards {
    display: grid;
    max-width: 1110px;
    grid-template-columns: repeat(auto-fill, 540px);
    grid-gap: 36px 29px;
    justify-content: center;
    margin: 0 auto ;
    color: #FFFFFF;
}

@media (max-width: 600px) {
    .cards {
        display: flex;
        flex-wrap: wrap;
        width: 90vw;
    }
}