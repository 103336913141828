.wrapper{
    max-width: 75%;
    margin: 0 auto;
}

.title{
    margin: 55px 0 50px;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 45px;
}
.contacts{
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-areas:
        "a b"
        "c b"
        "d d";
    grid-gap: 10px 0;
    margin: 20px auto 0;
}

.contacts div:nth-child(1){
grid-area: a;
} 

.contacts div:nth-child(2){
    grid-area: b;
} 

.contacts div:nth-child(3){
    grid-area: c;
} 

.contacts div:nth-child(4){
    grid-area: d;
} 

@media (max-width:1250px){
    .wrapper{
        max-width: 90%;
    }
}

/* @media (max-width:850px){
    .wrapper{
        max-width: 80vw;
    }
} */

@media (max-width:600px){
    .contacts{
        display: grid;
        grid-template-columns: none;
        grid-template-areas:
            "a"
            "c"
            "b"
            "d";
        grid-gap: 50px 0;
        margin: 20px auto 0;
    }
}

@media only screen
and (min-device-width:380px)
and (max-device-width: 992px)
and (orientation : landscape) {
    .wrapper{
        max-width: 100%;
    }
    .contacts{
        grid-gap: 10px 50px;
    }
}