.nav{
    display: flex;
    min-width: 330px;
    /* min-width: 180px; */
}

.navLink{
    font-size: 18px;
    line-height: 21px;
    color: #C7C7C7;
    text-decoration: none;
}

.navLink:not(:last-child) {
    margin-right:10px ;
}

.navLink:hover {
  color: #FFFFFF;
}

.navLink.active {
    
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    
    color: #FFFFFF;
}



/* burger menu */

.burgerNav{
  transform: translateX(-10px);
}

#menuToggle
{
  display: block;
  position: relative;
  
  z-index: 1;
  
  -webkit-user-select: none;
  user-select: none;

}

#menuToggle a
{
  text-decoration: none;
  color: #232323;
  
  transition: color 0.3s ease;
}

#menuToggle a:hover
{
  color: tomato;
}


#menuToggle input
{
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  
  cursor: pointer;
  
  opacity: 0; 
  z-index: 2; 
  
  -webkit-touch-callout: none;
}


#menuToggle span
{
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  
  background: #cdcdcd;
  border-radius: 3px;
  
  z-index: 1;
  
  transform-origin: 4px 0px;
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}

#menuToggle span:first-child
{
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2)
{
  transform-origin: 0% 100%;
}


#menuToggle input:checked ~ span
{
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
}


#menuToggle input:checked ~ span:nth-last-child(3)
{
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}


#menuToggle input:checked ~ span:nth-last-child(2)
{
  transform: rotate(-45deg) translate(0, -1px);
}


#menu
{
  position: absolute;
  padding-top: 50px;
  
  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  
  transform-origin: 0% 0%;
  transform: translate(100%, 0);           
  /* transform: translate(0, -170%); */
  
  transition: transform 0.5s ease-in-out;


  width: 100vw;
  height: 100vh;
  text-align: center;

}

#menu li
{
  padding: 10px 0;
  font-size: 22px;
}

#menuToggle input:checked ~ ul
{
  transform: translate(-85%, 0);
  margin-top: 14px;
  width: 100vw;
  height: 100vh;
  text-align: center;
}



@media (max-width: 1000px) {

  .nav{
      display: none;
  }
}

@media (min-width: 1000px) {
.burgerNav{
  display: none;
}
}

@media (min-width: 280px) {
    #menuToggle input:checked ~ ul
    {
        transform: translate(-82%, 0);
    }
}