.Wrapper {
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
}

input,textarea, select, button{
  outline: none;
} 

button{
  cursor: pointer;
}

