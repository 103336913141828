.main{
    background-image: url('background1.png');
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-repeat: no-repeat;
    background-size: cover;
}

.mainWrapper{
    margin: 0 auto;
    color: #ffffff;
}

.title{
    text-align: center;
    font-weight: bold;
    line-height: 37px;
    margin: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.blackWord{
    font-size: 32px;
    padding-bottom: 12px;
    color: #000000;
    border-bottom: 1.5px solid #000000;
}

.whiteWord{
    font-size: 28px;
    padding-bottom: 12px;
    color: #ffffff;
    border-bottom: 1.5px solid #ffffff;
}

.button{
    width: 256px;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    height: 54px;
    background-color: transparent;
    transition: transform .4s ease-in;
}
.buttonWhite{
    background-color: #FFFFFF;
    color: #000000;
    width: 256px;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    height: 54px;
    transition: transform .4s ease-in;
}

.buttonsContainer{
    display: flex;
    justify-content: space-between;
    max-width: 550px;
    flex-wrap: wrap;
}

.btnContainer{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
}

@media (max-width: 600px) {
    .buttonsContainer{
        margin-bottom: -100px;
        justify-content: center;
    }
    .button{
        margin: 20px auto;
    }

    .blackWord{
        font-size: 27px;
        text-align: center;
        width: 300px;
    }

    .whiteWord{
        font-size: 27px;
        text-align: center;
        width: 300px;
    }
}

@media (min-width: 600px){
    .button:hover {
        transform: scale(1.1);
    }
    .buttonWhite:hover {
        transform: scale(1.1);
    }
}

@media (max-width: 280px){
    .blackWord{
        width: 270px;
    }
    .whiteWord{
        width: 270px;
    }
}
