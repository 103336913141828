.header{
    left: 50%;
    width: 100%;
    max-width: inherit;
    transform: translateX(-50%);

    z-index: 99999;
}

.wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: 20px auto 0;
    max-width: 1110px;
}

/* @media (max-width:1400px){
    .wrapper{
        width: 60vw;
    }
} */
@media (max-width: 1400px) {
    .wrapper{
        width: 80vw;
    }
}
@media (max-width: 1024px) {
    .wrapper{
        width: 90vw;
        margin: 30px auto;
    }
}

@media only screen
and (min-device-width:380px)
and (max-device-width: 992px)
and (orientation : landscape) {
    .wrapper{
        margin: 20px auto;
    }
}