.form {
  text-align: center;
  display: grid;
  max-width: 660px;
  justify-content: center;
  /* grid-template-columns: 50% 50%; */
  grid-template-areas: 'a a' 'c c' 'e e' 'f f' 'g g';
  grid-gap: 37px 30px;
  margin: 20px auto 0;
}

/*inputsSection*/

.inputsSection {
  grid-area: a;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  grid-template-columns: 50% 50%;
  grid-template-areas: 'x y' 'z q';
}

.errorMessage {
  color: red;
  padding-left: 12px;
}

.inputsSection div {
  width: 271px;
  padding-bottom: 14px;
}

/* .inputsSection div:nth-child(3) {
    padding-bottom: 0;
} */

.inputsSection div:nth-child(4) {
  padding-bottom: 0;
}

.textInputX {
  grid-area: x;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
  height: 40px;
  background: #ffffff;
  border-radius: 12px;
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  border: none;
  color: #000000;
  padding-left: 12px;
  /* background: #E5E5E5; */
}

.textInputY {
  grid-area: y;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
  height: 40px;
  background: #ffffff;
  border-radius: 12px;
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  border: none;
  color: #000000;
  padding-left: 12px;
  /* background: #E5E5E5; */
}

.textInputZ {
  grid-area: z;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
  height: 40px;
  background: #ffffff;
  border-radius: 12px;
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  border: none;
  color: #000000;
  padding-left: 12px;
  /* background: #E5E5E5; */
}

.textInputQ {
  grid-area: q;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
  height: 40px;
  background: #ffffff;
  border-radius: 12px;
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  border: none;
  color: #000000;
  padding-left: 12px;
  /* background: #E5E5E5; */
}

/* .textInput::placeholder{
    padding-left: 12px;
} */

/* .selectMenu{
    height: 48px;
    background-color: #FDFDFD;
    background-position-x: 17%;
    background-repeat: no-repeat;
    background-position-y: center;
    background-image: url('../../../images/arrow/arrow.png');
    color: #686868;
    border-radius: 12px;
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    padding-left: 12px;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    position: relative;

    width: 430px;
} */

.textArea {
  background: #ffffff;
  border-radius: 12px;
  height: 100px;
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  border: none;
  resize: none;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
  grid-area: a;
  padding-left: 28px;
  padding-top: 18px;
  margin-top: 17px;
  /* background: #E5E5E5; */
}

/* .textArea::placeholder{
    padding-left: 28px;
    padding-top: 18px;
} */

/* UploadContainer */

.uploadContainer {
  background-color: #ffffff;
  color: #686868;
  border-radius: 12px;
  grid-area: a;
  /* padding: 12px; */
  /* justify-content: center; */
  /* background: #E5E5E5; */
  padding: 5px 5px 5px 5px;
}

.dashedWrapper {
  border-radius: 12px;
  /* border-image: url('../../../images/border/border.svg') 1 fill round;
    -moz-border-image: url('../../../images/border/border.svg') 1 fill round;
    -webkit-border-image: url('../../../images/border/border.svg') 1 fill round; 
    -o-border-image: url('../../../images/border/border.svg') 1 fill round; */
  border-style: dashed;
  border-width: 2px;
  height: 100%;
  width: 100%;
  outline: none;
}

.dashedWrapper div {
  display: flex;
  align-items: end;
  padding: 5px 5px 5px 5px;
}

.clipImg {
  height: 30px;
  width: 30px;
}

.dashedWrapper div div {
  position: relative;
  z-index: 10;
  width: 230px;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: -5px;
  margin-bottom: -5px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.fileInput {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  position: relative;
  justify-content: center;
  z-index: -10;
}

label {
  position: relative;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #6494bc;
}

.imgAccept {
  width: 50px;
  height: 50px;
}

/*RadioBtns*/
/*
.radioBtnGroup {
  grid-area: d;
}

.radioBtnGroupTitle {
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 31px;
  margin: 0 0 25px 0;
  color: #373737;
}

.formRadioBtn {
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 10px;
}

.formRadioBtn input[type='radio'] {
  display: none;
}

.formRadioBtn label {
  display: inline-block;
  cursor: pointer;
  padding: 15px 20px;
  border-radius: 12px;
  user-select: none;
  background: white;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
}

.formRadioBtn input[type='radio']:checked + label {
  background: #bdddfc;
}
*/

/* button */

.button {
  grid-area: g;
  display: block;
  margin: -25px auto 0;
  width: 255px;
  height: 48px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  border: none;
  color: #ffffff;
  background: #e13837;
  border-radius: 4px;
  transition: transform 0.4s ease-in;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@media (min-width: 600px) {
  .buttonHover:hover {
    transform: scale(1.1);
  }
}

.text {
  grid-area: e;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #686868;
  margin: -20px 0 0;
}

.approvedText {
  grid-area: f;
  text-align: center;
  /*color: #6494BC;*/
  color: #000000;
  margin: -40px;
  display: none;
}

@media (max-width: 1024px) {
  .form {
    grid-gap: 20px 10px;
  }
}

@media (max-width: 970px) {
  .form {
    max-width: 90vw;
  }
}

@media (max-width: 770px) {
  .form {
    max-width: 600px;
  }
  .selectMenu {
    background-position-x: 25%;
  }
}

@media (max-width: 600px) {
  .form {
    max-width: 350px;
    grid-template-columns: 90%;
    justify-content: center;
    grid-template-areas: 'a' 'b' 'c' 'd' 'e' 'f' 'g';
    grid-gap: 26px 30px;
    margin-top: 30px;
  }
  .textInput {
    height: 30px;
  }
  .selectMenu {
    height: 35px;
    background-position-x: 23%;
  }
  .text {
    margin: 0;
  }
  .inputsSection div {
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
  }
  .inputsSection div:nth-child(3) {
    padding-bottom: 14px;
  }
  .inputsSection div:nth-child(4) {
    padding-bottom: 14px;
  }
  .approvedText {
    max-width: 300px;
    margin: 0 auto 20px;
  }
}

@media only screen and (min-device-width: 380px) and (max-device-width: 992px) and (orientation: landscape) {
  .inputsSection div:nth-child(3) {
    padding-bottom: 14px;
  }
  .inputsSection div {
    width: -webkit-fill-available;
  }
  .radioBtnGroupTitle {
    font-size: 19px;
  }
  .approvedText {
    font-size: 1rem;
  }
}
