.title{
    margin: 50px auto 45px;
    font-style: normal;
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    line-height: 25px;
}
.backTitle{
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 25px;

    text-align: center;

    border-bottom: 1.5px solid #FFFFFF;
    padding-bottom: 10px;
    width: 230px
}
.description{
    max-width: 256px;
    margin: 0 auto 70px;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
}

.card {
    height: 239px;
    background-size: cover;
    position: relative;
    perspective: 1000px;
  }
.front, .back {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease-in;
    backface-visibility: hidden;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   }
.front img {
    max-width: 100%;
    min-width: 100%;
    height: auto;
  }
.back {
    transform: rotateY(180deg);
}


@media(min-width: 769px ){
    .card:hover .front {
        transform: rotateY(180deg);}
    .card:hover .back {
        transform: rotateY(360deg);}
}

/* @media (hover: hover) {
    .card:hover .front {
        transform: rotateY(180deg);}
    .card:hover .back {
        transform: rotateY(360deg);}
} */