.teamInfo{
    padding: 170px 0 150px;
}

@media only screen
and (min-device-width:380px)
and (max-device-width: 992px)
and (orientation : landscape) {
    .teamInfo{
        padding: 100px 0 150px;
    }
}