.partners{
    padding: 143px 0 140px;
}

@media (max-width: 600px) {
    .partners{
        padding: 100px 0;
    }
}

@media only screen
and (min-device-width:380px)
and (max-device-width: 992px)
and (orientation : landscape) {
    .partners{
        padding: 80px 0 80px;
    }

}
