.cards {
    display: grid;
    max-width: 950px;
    grid-template-columns: repeat(auto-fill, 315px);
    grid-gap: 18px 0;
    justify-content: center;
    margin: 160px auto 0;
    color: #FFFFFF;
}

@media (max-width: 280px) {
    .cards {
        grid-template-columns: repeat(auto-fill, 280px);
    }
}