.title{
    width: 307px;

    font-style: normal;
    font-weight: bold;
    font-size: 52px;
    line-height: 100%;
    /* or 60px */

    text-transform: uppercase;

    color: #FFFFFF;

    margin: 0;
}

.wrapper{
    max-width: 1110px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    min-height: 100vh;
}

.padding{
    padding-top: 240px;
    padding-bottom: 60px;
}

.description{
    max-width: 405px;

    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;

    color: #FFFFFF;

    margin: 40px 0 50px;
}

.icons{
    width: 57px;
    height: 57px;
    /*border-radius: 50%;*/
}

.buttonsAndHashtagsWrapper{
    align-items: flex-end;
    display: flex;
    width: 160px;
    flex-direction: column;
}

.button{
    width: 40px;
    height: 40px;

    border: 1px solid #FFFFFF;
    border-radius: 50%;
    background: transparent;
    color: white;
}

.buttonGrey{
    margin-left: 15px;
    background: rgba(196, 196, 196, 0.7);
}

.arrowText{

    margin: 20px 0 0 0;

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;

    text-transform: uppercase;

    color: #FFFFFF;

    border-bottom: solid 1px white;
    padding-bottom: 9px;
}

.hashtags{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 30px;
}

.hashtags p{
    margin: 0;
}

@media (max-width: 1200px) {
    .wrapper{
        max-width: 90vw;
    }
    .title{
        font-size: 45px;
    }
}

@media (max-width: 768px) {
    .title{
        font-size: 32px;
    }
}

@media (max-width: 600px) {
    .wrapper{
        flex-direction: column;
        justify-content: initial;
    }
    .padding{
        padding-top: 140px;
        display: grid;
        grid-template-columns: none;
        grid-template-areas:
                "a b"
                "c b";
        grid-gap: 0;
        margin: 0;
        grid-template-rows: max-content;
     }
    .title{
        font-size: 22px;
        max-width: 280px;
        grid-area: a;
    }
    .description{
        max-width: 280px;
        font-size: 14px;
        grid-area: c;
        padding-right: 10px;
    }
    .techStackIcons{
        grid-area: b;
    }

    .buttonsAndHashtagsWrapper{
        display: flex;
        padding-top: 0;
    }
}


@media (max-width: 320px) {
    .title{
        font-size: 14px;
        max-width: 180px;
    }
    .description{
        max-width: 180px;
        font-size: 12px;
    }

    .icons{
        width: 45px;
        height: 45px;
    }
}
