.teamInfo{
    padding: 0;
}

@media only screen
and (min-device-width:380px)
and (max-device-width: 992px)
and (orientation : landscape) {
    .teamInfo{
        padding: 0;
    }
}