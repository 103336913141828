.title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
}

.item {
    display: grid;
    grid-template-columns: 30% 55% 15%;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    align-items: center;
    color: #686868;
    margin-top: 10px;
}

.itemLine {
    border: none;
    border-bottom: 2px dotted #686868;
    color: #fff;
    background-color: #fff;
    height: 1px;
    width: 100%;
    margin: 0 10px;
}

.itemValue {
    text-align: right;
}

.itemKey {
    white-space: nowrap;
}

.totalKey {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #000000;
}

.totalLine {
    border-bottom: 2px dotted #E13837;
}

@media (max-width: 550px) {
    .title {
        text-align: center;
        margin-left: 15px;
        margin-right: 15px;
    }
    .item {
        grid-template-columns: 40% 0% 40%;
        justify-content: center;
    }
    .itemLine {
        width: 0;
    }
}