.wrapper{
    display: grid;
    max-width: 1110px;
    grid-template-columns: repeat(auto-fill, 285px);
    grid-gap: 117px 93px;
    justify-content: space-between;
    margin: 0 auto;
}

img {
    width: 100%;
}

@media (max-width: 770px) {
    .wrapper{
        grid-gap: 50px 40px;
        justify-content: center;
    }
}
@media (max-width: 280px) {
    .wrapper{
        grid-template-columns: repeat(auto-fill, 265px);
    }
}