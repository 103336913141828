.wrapper{
    margin: 0;
}

.border{
    border: 1px solid #000000;
    margin: 60px 0;
    background: #000000;
}

@media (max-width:600px){
    .border{
        display: none;
    }

    .wrapper{
        margin: 70px 0 30px;
    }
    
}